import React from 'react'
import moment from 'moment'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, Link } from 'gatsby'
import { PageLayout, Panel, Disclosure } from '../components'
import { KeyFacts, AnnualReturns } from '../components/fund'
import { getExpenseRatio } from '../utilities'

import * as styles from './fund.module.scss'

const fundNavLabel = process.env.GATSBY_NAV_TYPE === 'auv' ? 'AUV' : 'NAV';

const FundTemplate = ({ data }) => {
  const fund = data.otherFund;

  return <PageLayout id="funds" title={`${fund.name}`}>
    <div className={process.env.GATSBY_CLIENT === 'milliman' ? styles.milliman : styles.aig}>
      <Container>

        <Link to="/funds">
          <i className="mi-icon mi-h-arrow mi-link mi-r-180"></i>
          Return to fund list
        </Link>

        <h1 className="text-center my-5">{fund.name}</h1>


        {process.env.GATSBY_FUND_COMPONENTS.split(',').map(component => {

          if (component === 'summary')
            return <Panel key="summary">
              <Row>
                <Col className="mb-3">
                  <h2 className="mb-3">Summary</h2>
                  <p dangerouslySetInnerHTML={{ __html: fund.description}}></p>
                </Col>
                <Col className="mb-3">
                  <div className="d-flex justify-content-between">
                    <h2 className="mb-3">Key Facts</h2>
                    <small>As of {moment(fund.latestStats.date).format('MM/DD/yyyy')}</small>
                  </div>

                  <KeyFacts fund={fund} expenseRatio={getExpenseRatio(fund.strategySeries)} fundNavLabel={fundNavLabel} otherFund={true} />
                </Col>
              </Row>
            </Panel>

          else if (component === 'returns')
            return <Panel key="returns" className="mt-4">
              <Row>
                <Col>
                  <div className="d-flex justify-content-between">
                    <h2 className="mb-3">Average Annual Total Returns</h2>
                    <small>As of {moment(fund.latestStats.date).format('MM/DD/yyyy')}</small>
                  </div>

                  <AnnualReturns fund={fund} fundNavLabel={`Fund ${fundNavLabel}`} otherFund={true} />

                  <Disclosure>
                    <p className="mb-0">
                      The results shown are historical, for informational purposes only, and do not guarantee future results.
                    </p>
                  </Disclosure>
                </Col>
              </Row>
            </Panel>

          return null;
        })}

        <Disclosure style={{color: '#707070', marginTop: '2rem', padding: '1.25rem'}}>
          <h6><strong>Risk Disclosures</strong></h6>
          {process.env.GATSBY_CLIENT === 'milliman' && <>
            <p style={{fontSize: '1rem', fontWeight: 'bold'}}>You could lose money by investing in the Money Market Fund ("Fund"). Although the Fund seeks to preserve the value of your investment at $1.00 per share, it cannot guarantee it will do so. An investment in the Fund is not insured or guaranteed by the Federal Deposit Insurance Corporation or any other government agency. The Fund's sponsor has no legal obligation to provide financial support to the Fund, and you should not expect that the sponsor will provide financial support to the Fund at any time.</p>
            <p>As of the date of this Prospectus, Milliman has also agreed to voluntarily waive fees and/or reimburse expenses of the Fund to avoid a negative yield. Such yield waivers and reimbursements are voluntary and could be modified or terminated at any time at the discretion of Milliman without notice. There is no guarantee that the Fund will maintain a positive yield.</p>
            <p>Although the Fund and Master Fund seek to preserve the value of an investment at $1.00 per share, an investor may lose money by investing in the Fund and Master Fund. The share price of money market funds can fall below the $1.00 share price.</p>
            <p>The Fund and Master Fund's yield will vary as short-term securities mature or are sold and the proceeds are reinvested in other securities. When interest rates are very low or negative, the Fund and Master Fund may not be able to maintain a positive yield or pay its expenses out of current income without impairing the Fund and Master Fund's ability to maintain a stable net asset value. Additionally, inflation may outpace and diminish investment returns over time. Recent and potential future changes in monetary policy made by central banks and/or their governments may affect interest rates.</p>
            <p>The Fund and Master Fund could lose money over short periods due to short-term market movements and over longer periods during more prolonged market downturns. Assets may decline in value due to factors affecting financial markets generally or particular asset classes or industries represented in the markets. The value of a security or other asset may also decline due to general market conditions, economic trends or events that are not specifically related to the issuer of the security or other asset, or due to factors that affect a particular issuer, country, region, market, industry, sector or asset class.</p>
          </>}
          {process.env.GATSBY_CLIENT === 'aig' && <>
            <p style={{fontSize: '1rem', fontWeight: 'bold'}}>You could lose money by investing in the Money Market Fund ("Fund"). Although the Fund seeks to preserve the value of your investment at $1.00 per share, it cannot guarantee it will do so. An investment in the Fund is not insured or guaranteed by the Federal Deposit Insurance Corporation or any other government agency. The Fund's sponsor has no legal obligation to provide financial support to the Fund, and you should not expect that the sponsor will provide financial support to the Fund at any time.</p>
            <p>Advanced Outcomes Annuity offers structured outcome strategies with underlying funds that have characteristics unlike many other traditional investment products and may not be suitable for all investors. There is no guarantee that the outcomes for a specific fund term will be realized. For more information regarding whether an investment in these funds is right for you, please see the product and fund prospectuses.</p>
            <p>Variable annuities are long-term investments designed for retirement. Early withdrawals may be subject to withdrawal charges. Partial withdrawals may reduce benefits available under the contract, as well as the amount available upon a full surrender. Withdrawals of taxable amounts are subject to ordinary income tax and if taken prior to age 59½, an additional 10% federal tax may apply.</p>
            <p>An investment in Advanced Outcomes Annuity involves investment risk, including possible loss of principal. The contract, when redeemed, may be worth more or less than the total amount invested. Products and features may vary by state and may not be available in all states or firms. We reserve the right to change fees for features described in this material; however, once a contract is issued, the fees will not change. The purchase of Advanced Outcomes Annuity is not required for, and is not a term of, the provision of any banking service or activity.</p>
            <p>This material is general in nature, was developed for educational use only, and is not intended to provide financial, legal, fiduciary, accounting or tax advice, nor is it intended to make any recommendations. Applicable laws and regulations are complex and subject to change. Please consult with your financial professional regarding your situation. For legal, accounting or tax advice consult the appropriate professional.</p>
            <p>All contract and optional benefit guarantees are backed by the claims-paying ability of the issuing insurance company. They are not backed by the broker/dealer from which this annuity is purchased.</p>
            <p style={{fontSize: '1rem', fontStyle: 'italic'}}>Advanced Outcomes Annuity is sold by prospectus only. The prospectus contains the investment objectives, risks, fees, charges, expenses and other information regarding the contract and underlying funds, which should be considered carefully before investing. A prospectus may be obtained by calling 1-877-445-1262. Investors should read the prospectus carefully before investing. </p>
            <p>Contracts and features may vary by state or may not be available in all states.</p>
          </>}
        </Disclosure>

      </Container>
    </div>
  </PageLayout>
}

export const query = graphql`
  query($fundId: String!) {
    otherFund(fundId: { eq: $fundId }) {
      fundId,
      name,
      description,
      cusip,
      inceptionDate,
      initialStats {
        date
        fundNav
      }
      latestStats {
        date
        fundNav
      }
      stats {
        date
        fundNav
      }
      resourceLinks {
        label
        link
      }
    }
  }
`

export default FundTemplate
